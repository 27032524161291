:root{
  --CSUF-Red: #CC0033;
  --CSUF-Blue: #00235D;
}

*{
  margin: 0;
  padding: 0;
  text-align: center;
}

html{
  /* background-image: url('images/queen.png');
  background-size: 100%; */
  background-color:green;
  
  	/*prevent text highlights*/
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}

.App {
  text-align: center;
}

#subtitle{
  margin: 1vh auto;
  font-size: 120%;
  width: 50%;
}

footer{
  position: absolute;
  bottom: 0;
  width: 100%;
}
footer li{
  display: inline-block;
  margin: 0 1vw;
}
footer ul{
  margin: 0;
  list-style-type: none;
}

table#options{ 
  /* background-color:red; */
  margin: 2vh auto;
  border: 1px solid black;
  /* height: 100%; */
  /* width: 75%; */
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 2vh 1vw;
 }

#options td{
  /* word-wrap: break-word; */
  height: 100%;
  /* width: 100%; */
}

input{
  vertical-align: middle;
}

caption{
  text-align: left;
}

table.board{
  /* Centers table */
  margin: 0 auto;
  border-collapse: collapse;
}

.light{
  background-color:white;
  /* background-image: url('./images/queen.png');
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center; */
}
.dark{
  background-color:black;
  /* background-image: url('images/queen.png');
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center; */
}

.selected{
  background-image: url('images/queen.png');
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
}

table.board td{
  height: 5vh;
  width: 5vw;
  /* background-image: url('images/queen.png'); */
}

nav ul{
  list-style-type: none;
  background-color: black;
  overflow: auto;
}

nav li{
  float: left;
  padding: 4vh 4vw;
  /* border-radius: 20vh 20vw 0 0; */
}

nav a{
  text-decoration: none;
  color: white;
}

.NavLink{
  background-color: black;
}

.NavLink-Active{
  background-color: green;
}

h1, h2{
  padding: 2vh 5vw 0;
}

h3{
  margin: 5vh 0 0 0;
}

p{
  /* margin: 5vh 5vw; */
  padding: 0 5vw;
  width: 75%;
  margin: 1vh auto 2vh;
}

img#homeQueen{
  margin: 5vh 5vw;
  width: 25%;
  height: 25%;
}

table.results{
  margin: auto;
  border-collapse: collapse;
}
.results td, .results th{
  padding: 0 1vw;
}

ul li{
  list-style-type: none;
}

a{
  text-decoration:none;
  color: black;
}

a.about:hover{
  color: var(--CSUF-Red);
  background-color: var(--CSUF-Blue);
}

tr.notSolution{
  background-color: rgb(54, 0, 0);
}

tr.solution:hover, tr.notSolution:hover{
  background-color: white;
}